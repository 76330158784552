import { ActionTypes } from "./actions";

const initialState = {
  user: {
    _id: "",
    name: "",
    email: "",
    loading: false,
    isAuth: false,
    error: "",
  },
  conversations: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN:
    case ActionTypes.USER_LOADING:
    case ActionTypes.SIGN_IN_ERROR:
      return { ...state, user: action.payload };

    case ActionTypes.SIGN_OUT:
      return { ...state, user: {}, conversations: [] };

    case ActionTypes.CONVERSATION:
      return { ...state, conversations: action.payload };

    case ActionTypes.SEND_MESSAGE:
      return { ...state, chatHistory: [...state.chatHistory, action.payload] };

    default:
      return state;
  }
};

export { initialState, reducer };
