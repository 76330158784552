import React, { createContext, useReducer, useContext, useEffect } from "react";
import { reducer, initialState } from "./reducer";

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const loadStateFromServer = async () => {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        user = { ...user, isAuth: true };
        dispatch({ type: "SIGN_IN", payload: user });
      }
    };

    loadStateFromServer();
  }, []);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

// Custom hook to use the store
const useStore = () => useContext(StoreContext);

export { StoreProvider, useStore };
