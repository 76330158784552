import React, { useState } from "react";
import { createNewConversation } from "../../api/conversation";
import { useStore } from "../../context/StoreProvider";
const NewConversation = () => {
  const { state } = useStore();
  const { _id: userId } = state?.user || {};
  const [newConversationTitle, setNewConversationTitle] = useState("");
  const [isAddingNew, setIsAddingNew] = useState(false);
  const handleAddNewClick = () => {
    setIsAddingNew(!isAddingNew);
  };

  const handleNewConversationSave = async () => {
    if (newConversationTitle.trim()) {
      setNewConversationTitle("");
      await createNewConversation({
        title: newConversationTitle,
        message: [],
        userId,
      });
      setIsAddingNew(false);
    }
  };
  return (
    <div className="p-4">
      <button
        onClick={handleAddNewClick}
        className="w-full mb-4 px-4 py-2 bg-gray-900 text-white rounded hover:bg-gray-600"
      >
        New Conversation
      </button>
      {isAddingNew && (
        <div className="flex items-center justify-between p-2 border-b border-gray-300">
          <input
            type="text"
            value={newConversationTitle}
            onChange={(e) => setNewConversationTitle(e.target.value)}
            onBlur={handleNewConversationSave}
            autoFocus
            className="flex-1 mr-2 p-1 border border-gray-300 rounded"
          />
        </div>
      )}
    </div>
  );
};

export { NewConversation };
