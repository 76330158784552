import { fetchConversation } from "../api/conversation";
import { login } from "../api/auth";
import { auth } from "../helper/auth-helper";
// src/context/actions.js
export const ActionTypes = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  SIGN_IN_ERROR: "SIGN_IN_ERROR",
  USER_LOADING: "USER_LOADING",
  REGISTER: "REGISTER",
  CONVERSATION: "CONVERSATION",
  CREATE_CONVERSATION: "CREATE_CONVERSATION",
  SEND_MESSAGE: "SEND_MESSAGE",
};

export const signIn = async ({ userLogin, dispatch }) => {
  try {
    let user = { loading: true, error: "", isAuth: false };
    dispatch({ type: ActionTypes.USER_LOADING, payload: user });
    const data = await login(userLogin);

    if (data.error) {
      user = { ...user, loading: false, error: data.error };
      dispatch({
        type: ActionTypes.SIGN_IN_ERROR,
        payload: user,
      });
    }
    if (data.user) {
      user = { ...data.user, isAuth: true, loading: false, error: "" };
      auth.setToken(data, () => {
        dispatch({
          type: ActionTypes.SIGN_IN,
          payload: user,
        });
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const signOut = (dispatch, navigate) => {
  dispatch({ type: "SIGN_OUT" });
  auth.clearToken(() => navigate("/"));
};

export const register = (user) => ({
  type: ActionTypes.REGISTER,
  payload: user,
});

export const loadConversation = async (userId, dispatch, signal) => {
  try {
    const response = await fetchConversation(userId, signal);
    dispatch({
      type: ActionTypes.CONVERSATION,
      payload: response?.conversations,
    });
  } catch (e) {
    console.error(e);
  }
};

export const sendMessage = (message) => ({
  type: ActionTypes.SEND_MESSAGE,
  payload: message,
});
