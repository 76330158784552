import React from "react";
import { NewConversation } from "./NewConversation";
import { ConversationList } from "./ConversationList";

const Sidebar = ({ conversations, onSelectConversation }) => {
  return (
    <div className="resizable bg-gray-200 text-gray-900 w-64 min-w-[300px] max-w-[400px] h-screen overflow-y-auto">
      <div className="sticky top-0 bg-gray-200 ">
        <NewConversation />
      </div>
      <div className="mt-4 z-0">
        <ConversationList
          conversations={conversations}
          onSelectConversation={onSelectConversation}
        />
      </div>
    </div>
  );
};

export { Sidebar };
