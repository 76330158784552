const fetchConversation = async (userId, signal) => {
  try {
    const response = await fetch(`/api/conversations/${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("jwt_token")
        )}`,
      },
      signal: signal,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Fetch aborted");
    } else {
      throw error;
    }
  }
};

const createNewConversation = async (newConversation) => {
  try {
    const response = await fetch(`/api/conversations`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("jwt_token")
        )}`,
      },
      body: JSON.stringify(newConversation),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

const updateConversation = async (
  conversationId,
  userId,
  updatedConversation
) => {
  try {
    const response = await fetch(
      `/api/conversations/${userId}/${conversationId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `Bearer ${JSON.parse(
            sessionStorage.getItem("jwt_token")
          )}`,
        },
        body: JSON.stringify(updatedConversation),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

const deleteConversation = async (conversationId, userId) => {
  try {
    const response = await fetch(
      `/api/conversations/${userId}/${conversationId}`,
      {
        method: "DElETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `Bearer ${JSON.parse(
            sessionStorage.getItem("jwt_token")
          )}`,
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
export {
  fetchConversation,
  createNewConversation,
  updateConversation,
  deleteConversation,
};
