import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { updateConversation, deleteConversation } from "../../api/conversation";
import { useStore } from "../../context/StoreProvider";
const ConversationList = ({ conversations, onSelectConversation }) => {
  const { state } = useStore();
  const { _id: userId } = state?.user || {};
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  console.log(conversations);
  const handleEditClick = (index, title) => {
    setEditingIndex(index);
    setEditedTitle(title);
  };

  const handleSaveClick = async (conversation) => {
    // Save the edited title (you can add your save logic here)
    conversation.title = editedTitle;
    console.log(conversation._id);
    await updateConversation(conversation._id, userId, conversation);
    setEditingIndex(null);
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm("Are you sure you want to delete this conversation?")) {
      const index = conversations.findIndex(
        (conversation) => conversation._id === id
      );

      if (index !== -1) {
        conversations.splice(index, 1);
        await deleteConversation(id, userId);
        localStorage.removeItem("activeConversation");
      }
    }
  };

  const handleActiveConversation = (conversation) => {
    onSelectConversation(conversation);
    if (typeof window !== "undefined") {
      let actConversation = {
        title: conversation["title"],
        _id: conversation["_id"],
      };
      localStorage.setItem(
        "activeConversation",
        JSON.stringify(actConversation)
      );
    }
  };

  return (
    <div className="px-4">
      {conversations
        ?.slice()
        .reverse()
        .slice(0, 10)
        .map((conversation, i) => (
          <div
            onClick={() => handleActiveConversation(conversation)}
            key={i}
            className="flex items-center justify-between p-2 border-b border-gray-300 hover:bg-gray-100 group cursor-pointer"
          >
            {editingIndex === i ? (
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={() => handleSaveClick(conversation)}
                autoFocus
                className="flex-1 mr-2 p-1 border border-gray-300 rounded"
              />
            ) : (
              <span className="flex-1 mr-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
                {conversation.title}
              </span>
            )}
            <div className="flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <FaEdit
                onClick={() => handleEditClick(i, conversation.title)}
                className="cursor-pointer text-gray-500 bg-blacks"
              />
              <FaTrash
                onClick={() => handleDeleteClick(conversation._id)}
                className="cursor-pointer text-gray-500"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export { ConversationList };
