import { useRef, useEffect } from "react";

const ChatbotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    className="w-10 h-10"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 11c.764 0 1.383.474 1.618 1.162m-3.236 0C10.617 11.474 11.236 11 12 11m1.618 1.162c.016-.04.025-.085.038-.129.13-.451.563-.807 1.105-.907m-2.789 1.036C12.03 12.071 12 12.036 12 12c0-.036.03-.071.038-.107m0 .214A1.01 1.01 0 0012 12c0 .036-.03.071-.038.107m.038-.214c-.476.054-.908.41-1.039.861m2.002-.867c-.477-.074-.935-.074-1.412 0m-1.039.861C10.382 12.474 11.764 13 12 13c.236 0 1.618-.526 1.618-1.162m0 0c-.016.04-.025.085-.038.129m-.892.778c.29.217.742.349 1.122.349.378 0 .83-.132 1.118-.349m-1.118-.349a.603.603 0 00-1.118 0m-1.105-.907c.09.025.184.04.28.04m-2.082-.04a2.058 2.058 0 002.062 2.062m0-2.062c0-.55.224-1.048.588-1.414M9.5 12.5h-1m2.5 0h-1M16.5 12.5h-1m2.5 0h-1m-.5 2.5a.5.5 0 00-1 0 .5.5 0 001 0zM7.5 15c-.44.121-.88.5-.92 1H9m-1.5-1c-.04.5-.48.879-.92 1m1.84 0c.04.5.48.879.92 1M7.5 18c.44-.121.88-.5.92-1H9m-1.5 1c-.04-.5-.48-.879-.92-1"
    />
  </svg>
);

const MarkdownRenderer = ({ markdownContent }) => {
  const markdownContainerStyle = {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap", // Preserve whitespace and wrap text
    maxWidth: "100%",
    padding: "10px", // Add padding to make it look better
    fontFamily: "Arial, sans-serif", // Set a clean font
  };

  // Clean the markdown content by handling common whitespace issues
  const cleanContent = markdownContent
    .replace(/\u00A0/g, " ") // Replace non-breaking spaces with regular spaces
    .replace(/^\s+|\s+$/gm, "") // Trim leading and trailing whitespace on each line
    .replace(/\s{2,}/g, " ") // Replace multiple spaces with a single space
    .trim();

  // Function to process and format code blocks nicely
  const processCode = (content) => {
    const codeBlockRegex = /```([a-zA-Z0-9]*)\n([\s\S]*?)\n```/g;
    return content.replace(codeBlockRegex, (match, language, code) => {
      return `<pre><code class="language-${language}">${code}</code></pre>`;
    });
  };

  const processedContent = processCode(cleanContent);

  return (
    <div style={markdownContainerStyle}>
      <div
        // Using dangerouslySetInnerHTML to inject processed content (including code blocks)
        dangerouslySetInnerHTML={{ __html: processedContent }}
      />
    </div>
  );
};

export { MarkdownRenderer };
const ThinkingIndicator = () => {
  return (
    <div className="flex items-center space-x-2">
      <span className="text-gray-500">Thinking</span>
      <div className="flex space-x-1">
        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-100"></div>
        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
      </div>
    </div>
  );
};
const ChatConversation = (props) => {
  const messages = props.messages;

  const chatEndRef = useRef(null);

  // Scroll to the bottom when a new message arrives
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex flex-col  mb-4 h-screen overflow-y-auto">
      <div className="flex-grow">
        {messages?.map((msg) => (
          <div key={msg.messageId} className={`flex flex-col mb-4 `}>
            <div className={`flex justify-end mb-4`}>
              <div
                className={`flex flex-row gap-4 items-start bg-gray-200 text-gray-900
                 p-4 rounded-lg shadow-md whitespace-pre-wrap break-words `}
              >
                <MarkdownRenderer markdownContent={msg.message} />
                {/* {msg.message} */}
                {/* <MarkdownRenderer
                  markdownContent={msg.message}
                  className="flex-shrink "
                /> */}
                <div
                  className={`w-10 h-10 bg-gray-900 rounded-full flex items-center justify-center text-white font-bold `}
                >
                  You
                </div>
              </div>
            </div>
            {msg.loading && (
              <div className="text-sm text-gray-500">
                <ThinkingIndicator />
              </div>
            )}
            {msg.error && (
              <div className="text-sm text-red-500">{msg.error}</div>
            )}
            {msg.response && (
              <div className="flex flex-row gap-4 items-start max-w-[95%]">
                <div
                  className={`w-10 h-10 bg-gray-900 rounded-full flex items-center justify-center text-white font-bold flex-shrink-0`}
                >
                  <ChatbotIcon />
                </div>
                <MarkdownRenderer
                  markdownContent={msg.response}
                  className="flex-shrink"
                />
                <div ref={chatEndRef} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { ChatConversation };
