import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import { StoreProvider } from "./context/StoreProvider";
import "./index.css";

const rootElement = document.getElementById("app");
const root = ReactDOM.createRoot(rootElement);
const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

root.render(
  <React.StrictMode>
    <StoreProvider>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <App />
      </ErrorBoundary>
    </StoreProvider>
  </React.StrictMode>
);
