import React from "react";
import { useStore } from "../../context/StoreProvider";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "../../context/actions";

const Menu = () => {
  const { state, dispatch } = useStore();
  const navigate = useNavigate();
  const user = state.user;
  const { name, isAuth } = user;
  const handleSignOut = () => {
    signOut(dispatch, navigate);
  };

  //const isAuthenticated = auth.isSignedIn();
  const handleBrandingClick = () => {
    if (isAuth) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <button role="link" onClick={handleBrandingClick}>
        <h1 className=" text-white drop-shadow-2xl flex-grow text-3xl font-semibold text-center md:text-left md:flex-shrink-0  ">
          Chat Bot
        </h1>
      </button>
      <div className="hidden sm:ml-6 sm:block">
        <div className="flex space-x-4">
          {!isAuth && (
            <>
              <Link
                to="/signup"
                className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Register
              </Link>
              <Link
                to="/login"
                className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Sign In
              </Link>
            </>
          )}
          {isAuth && (
            <>
              <span className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                Welcome {name}
              </span>

              <button
                onClick={handleSignOut}
                className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Logout
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { Menu };
