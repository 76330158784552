import { useState } from "react";
import { Link } from "react-router-dom";
import { signup } from "../../api/register";
export const Register = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    isLoading: false,
    message: "",
  });

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUser({ ...user, isLoading: true, message: "" });
    try {
      const data = await signup({
        name: user.name,
        email: user.email,
        password: user.password,
      });

      if (data.error) {
        setUser({ ...user, isLoading: false, message: data.error });
      }
      if (data.user) {
        setUser({ ...user, isLoading: false, message: data.message });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <main className=" mx-auto my-0 flex justify-center flex-grow flex-col items-center ">
      <form
        onSubmit={handleSubmit}
        className="shadow-lg w-[350px] sm:w-[400px] pt-10 pr-10 px-10 pb-4 rounded-md"
      >
        <h2 className="text-2xl font-semibold text-center">
          Create an Account
        </h2>
        <div className="flex flex-col w-full mt-10 gap-2">
          {user.message && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              {user.message}
            </div>
          )}
          <div className={`sm:col-span-4 ${user.message && "mt-4"}`}>
            <label
              htmlFor="name"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Name
            </label>
            <div className="mt-2">
              <input
                id="name"
                name="name"
                type="text"
                value={user.name}
                onChange={handleChange}
                autoComplete="given-name"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="email"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                value={user.email}
                onChange={handleChange}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="password"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Password
            </label>
            <div className="mt-2">
              <input
                value={user.password}
                onChange={handleChange}
                id="password"
                name="password"
                type="password"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>
          <button className="bg-gray-900 px-4 py-2 shadow-lg text-white  rounded-md text-lg">
            Register
          </button>

          <div className="mt-4 w-full flex flex-row justify-between">
            <div className="sm:col-span-2">
              <Link
                to="/login"
                className="mt-4 text-right w-full text-black hover:text-maroon-200 underline"
              >
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </form>
    </main>
  );
};
