import React, { useState, useEffect, useRef } from "react";

const ExpandingTextarea = ({ value, onChange }) => {
  const [height, setHeight] = useState("auto");
  const textareaRef = useRef(null);
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.target.form.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };
  useEffect(() => {
    if (textareaRef.current) {
      if (value === "") {
        setHeight("auto");
      } else {
        setHeight("auto");
        setHeight(`${textareaRef.current.scrollHeight}px`);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      rows={1}
      style={{
        height: height,
        maxHeight: "5em", // 5 lines height
        overflow:
          textareaRef.current &&
          textareaRef.current.scrollHeight > textareaRef.current.clientHeight
            ? "auto"
            : "hidden",
      }}
      className="w-full p-2 border rounded"
    />
  );
};

export { ExpandingTextarea };
