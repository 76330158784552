import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../context/StoreProvider";
import { signIn } from "../../context/actions";

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { from } = location.state || { from: { pathname: "/dashboard" } };

  const { state, dispatch } = useStore();
  const { error, loading, isAuth } = state?.user;
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserLogin({ ...userLogin, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn({ userLogin, dispatch });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate(from.pathname);
    }
  }, [isAuth, navigate, from]);

  return (
    <main className=" mx-auto my-0 flex justify-center flex-grow flex-col items-center ">
      <form
        onSubmit={handleSubmit}
        className="shadow-lg w-[350px] sm:w-[400px] pt-10 pr-10 px-10 pb-4 rounded-md"
      >
        <h2 className="text-2xl font-semibold text-center">Sign in</h2>
        <div className="flex flex-col w-full mt-10 gap-2">
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              {error}
            </div>
          )}
          <div className={`sm:col-span-4 ${error && "mt-4"}`}>
            <label
              htmlFor="email"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={userLogin.email}
                onChange={handleChange}
                autoComplete="email"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="password"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Password
            </label>
            <div className="mt-2">
              <input
                value={userLogin.password}
                onChange={handleChange}
                id="password"
                name="password"
                type="password"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <button
            disabled={loading}
            className={`mt-2 px-4 py-2 shadow-lg rounded-md text-lg ${
              loading ? "bg-gray-400 text-gray-700" : "bg-gray-900 text-white"
            }`}
          >
            Login
          </button>

          <div className="mt-4 w-full flex flex-row justify-between">
            <div className="sm:col-span-2 sm:col-start-1">
              <Link
                to="/forgot-password"
                className="mt-4 text-right w-full text-black hover:text-maroon-200 underline"
              >
                Forgot Password?
              </Link>
            </div>

            <div className="sm:col-span-2">
              <Link
                to="/signup"
                className="mt-4 text-right w-full text-black hover:text-maroon-200 underline"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </form>
    </main>
  );
};
