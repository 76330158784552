const apiUrl = process.env.REACT_APP_API_URL;
const SECRET = process.env.REACT_APP_MY_SECRET;
console.log(SECRET);
export const login = async (user) => {
  try {
    const res = await fetch(`/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (e) {
    console.error(e);
  }
};

export const logout = async () => {
  try {
    const res = await fetch("/auth/logout", {
      method: "GET",
    });
    return await res.json();
  } catch (e) {
    console.error(e);
  }
};
