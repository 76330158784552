// ChatBox.jsx
import React, { useState } from "react";
import { ExpandingTextarea } from "../textarea/ExpandableTextArea";
const ChatBox = (props) => {
  const [message, setMessage] = useState("");
  // const handleChange = (e) => {
  //   setMessage(e.target.value);
  // };
  const { handleSubmit } = props;

  const SubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(message);
    setMessage("");
  };
  return (
    <form
      onSubmit={SubmitHandler}
      className="bg-white p-4 rounded shadow flex items-center sticky bottom-0 max-w-full gap-4"
    >
      <ExpandingTextarea value={message} onChange={setMessage} />
      <button className="bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded">
        Send
      </button>
    </form>
  );
};

export { ChatBox };
