import React from "react";
import { BrowserRouter } from "react-router-dom";

import { MainRouter } from "./components/route/MainRouter";

const App = () => {
  return (
    <div className="bg-gray-100 flex flex-col h-screen">
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
    </div>
  );
};

export default App;
