const signup = async (data) => {
  try {
    const user = await fetch(`/api/users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await user.json();
  } catch (e) {
    console.error(e);
  }
};

export { signup };
