import React, { useEffect, useState } from "react";
import { useStore } from "../../context/StoreProvider";
import { loadConversation } from "../../context/actions";
import { Sidebar } from "./Sidebar";
import { ChatWindow } from "./ChatWindow";

const Dashboard = () => {
  const { state, dispatch } = useStore();
  const { conversations } = state;
  const { _id: userId } = state?.user || {};
  const [activeConversation, setActiveConversation] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const loadConversations = async () => {
      try {
        await loadConversation(userId, dispatch, signal);
      } catch (err) {
        console.log(err);
      }
    };

    loadConversations();

    return () => {
      abortController.abort();
    };
  }, [userId, dispatch]);

  return (
    <div className=" flex flex-row">
      <Sidebar
        conversations={conversations}
        onSelectConversation={setActiveConversation}
      />
      <ChatWindow activeConversation={activeConversation} />
    </div>
  );
};

export { Dashboard };
