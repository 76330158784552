import React from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { Register } from "../landing/Register";
import { Header } from "../header/Header";
import { Login } from "../landing/Login";
import { ForgotPassword } from "../landing/ForgotPassword";
import { Dashboard } from "../dashboard/Dashboard";
import { NotFound } from "../NoFound";

const MainRouter = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export { MainRouter };
