import { Link } from "react-router-dom";
export const ForgotPassword = () => {
  return (
    <main className=" mx-auto my-0 flex justify-center flex-grow flex-col items-center ">
      <div className="shadow-lg w-[350px] sm:w-[400px] pt-10 pr-10 px-10 pb-4 rounded-md">
        <h2 className="text-2xl font-semibold text-center">Reset Password</h2>
        <div className="flex flex-col w-full mt-10 gap-2">
          <div className="sm:col-span-4">
            <label
              for="email"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <button
            eventHandler={() => true}
            className="bg-gray-900 px-4 py-2 shadow-lg text-white  rounded-md text-lg"
          >
            Send
          </button>

          <div className="mt-4 w-full flex flex-row justify-between">
            <div className="sm:col-span-2">
              <Link
                to="/login"
                className="mt-4 text-right w-full text-black hover:text-maroon-200 underline"
              >
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
