import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { ChatConversation } from "./ChatConversation";
import { useStore } from "../../context/StoreProvider";
import { ChatBox } from "./ChatBox";
import { chat } from "../../api/chat";
import {
  createNewConversation,
  updateConversation,
} from "../../api/conversation";

const ChatWindow = ({ activeConversation }) => {
  const { state } = useStore();
  const userId = state?.user?._id;
  const [messages, setMessages] = useState([]);
  const [conversation, setConversation] = useState({ ...activeConversation });

  useEffect(() => {
    setConversation({ ...activeConversation });
    setMessages(activeConversation?.messages || []);
  }, [activeConversation]);

  const handleSubmit = async (message) => {
    let localActiveConversation;
    if (typeof window !== "undefined") {
      localActiveConversation = JSON.parse(
        localStorage.getItem("activeConversation")
      );
    }
    console.log(localActiveConversation);
    const conversationId = localActiveConversation?._id;

    const title = localActiveConversation?.title;
    setConversation({ conversationId, title });
    const newMessage = {
      messageId: uuidv4(),
      message: message,
      response: "",
      error: "",
      loading: true,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    try {
      const response = await chat({ query: message });

      if (response.error) {
        setMessages({ ...messages, loading: false, error: response.error });
      }
      if (response.result) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.messageId === newMessage.messageId
              ? { ...msg, response: response.result, error: "", loading: false }
              : msg
          )
        );

        const newConversation = {
          messages: [...messages, { ...newMessage, response: response.result }],
          userId,
          title,
        };
        if (conversationId) {
          await updateConversation(conversationId, userId, {
            ...newConversation,
            _id: conversationId,
          });
        } else {
          const res = await createNewConversation({
            ...newConversation,
            title: message,
          });
          if (typeof window !== "undefined") {
            let actConversation = {
              title: res.conversation["title"],
              _id: res.conversation["_id"],
            };
            localStorage.setItem(
              "activeConversation",
              JSON.stringify(actConversation)
            );
          }
          setConversation({
            ...conversation,
            messages: res.conversation.messages,
            _id: res.conversation._id,
            title: res.conversation.title,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  console.log(messages);
  return (
    <div className="flex flex-1 flex-col bg-gray-100 p-4 h-screen max-w-[840%]">
      <h2 className="text-xl font-bold mb-4">Chat Conversation</h2>
      <div className="flex-grow flex flex-col overflow-hidden">
        <ChatConversation messages={messages} />
      </div>
      <ChatBox handleSubmit={handleSubmit} />
    </div>
  );
};

export { ChatWindow };
