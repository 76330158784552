import { logout } from "../api/auth";

const auth = {
  isSignedIn() {
    if (typeof window == "undefined") {
      return false;
    }
    if (sessionStorage.getItem("jwt_token")) {
      return JSON.parse(sessionStorage.getItem("jwt_token"));
    } else {
      return false;
    }
  },
  setToken(data, cb) {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("jwt_token", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));
    }
    cb();
  },
  clearToken(cb) {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem("jwt_token");
      localStorage.removeItem("user");
    }
    cb();
    logout().then((data) => {
      document.cookie =
        "chatbot_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    });
  },
};

export { auth };
