// import { useEffect, useState } from "react";
import { Menu } from "./Menu";

const Header = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <header role="banner" className="sticky top-0  h-20 bg-gray-900 z-10">
      <div className="relative h-20 mx-auto flex justify-between items-center px-4  ">
        <Menu />
      </div>
    </header>
  );
};

export { Header };
